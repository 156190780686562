.icon {
  width: 170px;
  height: 170px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.textTitle {
  font-size: calc(1em + 4vw);
}

.textSubTitle {
  font-size: calc(1em + 1vw);
  text-align: center;
  padding: 10px;
}


.logo {
  z-index: 555;
  margin-top: -55px;

  img {
    width: 80px;
    height: 80px;
  }
}
